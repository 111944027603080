<template>
  <div class="use-giftcode-success-panel text-center">
    <div class="icon">
      <b-icon icon="check-circle" />
    </div>
    <div class="panel-heading">
      <h1 class="panel-title">{{$t("Used giftcode successfully")}}</h1>
      <div class="panel-description">{{message}}</div>
    </div>
    <div class="panel-body text-left">
      <b-col>
        <table class="ml-auto mr-auto">
          <tr>
            <td class="font-weight-bold">{{$t("Game")}}:</td>
            <td class="pl-2">{{gameName}}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{$t("Server")}}:</td>
            <td class="pl-2">{{serverName}}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">{{$t("Role name")}}:</td>
            <td class="pl-2">{{roleName}}</td>
          </tr>
        </table>
      </b-col>
      
      <b-button variant="outline-primary" class="btn-rounded font-weight-bold" block size="lg" @click="$emit('useOtherCode')">{{$t("Use other code")}}</b-button>
      <b-button variant="primary" class="btn-rounded font-weight-bold" block size="lg" @click="$emit('viewOtherCodes')">{{$t("View other codes")}}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UseGiftCodeSuccessPanel",
  data() {
    return {
      message: "",
      gameName: "",
      serverName: "",
      roleName: ""
    }
  },
  created() {
    const data = this.$store.getters.giftCodeSuccess;
    if (!data) {
      this.$router.push({name: "GiftCodeIndex"})
      return;
    }
    
    Object.keys(data).forEach(key => this[key] = data[key]);
    this.$store.dispatch("setGiftCodeSuccess", null);
  }
}
</script>

<style scoped lang="scss">
.use-giftcode-success-panel {
  .icon {
    font-size: 120px;
    color: green;
  }
  
  .panel-heading {
    margin-top: -15px;
    margin-bottom: 15px;
    
    h1 {
      font-size: 18px;
      font-weight: 700;      
      margin-bottom: 2px;
    }
  }
  
  .panel-body {
    table {
      width: 100%;
      max-width: 300px;
      margin-bottom: 30px;
    }
  }
}
</style>