<template>
  <UseGiftCodeSuccessPanel @useOtherCode="$router.push({name: 'UseGiftCode'})" @viewOtherCodes="$router.push({name: 'GiftCodeIndex'})"/>
</template>

<script>
import UseGiftCodeSuccessPanel from "@/components/panels/giftcode/UseGiftCodeSuccessPanel";

export default {
  name: "UseSuccess",
  components: {UseGiftCodeSuccessPanel}
}
</script>